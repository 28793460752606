import React from 'react';

import AtTheDoctorComparingImage from '../../assets/images/at-the-doctor-comparing-image.jpg';
import AtTheDoctorComparingImageWebp from '../../assets/images/at-the-doctor-comparing-image.webp';
import AtTheDoctorComparingImage_s from '../../assets/images/at-the-doctor-comparing-image-s.jpg';
import AtTheDoctorComparingImageWebp_s from '../../assets/images/at-the-doctor-comparing-image-s.webp';

import useIsEdge from '../../util/use-is-edge';

function AtTheDoctorComparing() {
  const isEdge = useIsEdge();

  function goToQuestions() {
    const sectionOffsetTop =
      document.getElementById('questions-section')!.offsetTop;

    const headerHeight = document
      .querySelector('.site-header')!
      .getBoundingClientRect().height;

    window.scrollTo({
      top: sectionOffsetTop - headerHeight
    });
  }

  return (
    <section>
      <div className={`container mx-auto ${isEdge ? 'is-edge-container' : ''}`}>
        <div className="at-the-doctor-card mx-auto flex flex-col lg:flex-row items-center text-white-100 relative">
          <div className="at-the-doctor-card-content">
            <h2 className="font-mulish font-bold text-center lg:text-left mx-auto lg:ms-0">
              Comparing your endo pain to barbed wire isn’t dramatic, it’s real.
            </h2>

            <p className="text-center lg:text-left mx-auto lg:ms-0">
              It can be difficult to communicate your pain, but it’s important
              that your doctor listens to and understands the details of how
              endo pain impacts your daily activities.
            </p>

            <p className="font-semibold text-center lg:text-left mx-auto lg:ms-0">
              Use these questions to help guide the conversation and inform your
              next treatment decision.
            </p>

            <button
              className="white-btn block text-center mx-auto lg:ms-0"
              onClick={goToQuestions}
            >
              <span className="block">START THE CONVERSATION</span>
            </button>
          </div>

          <div className="at-the-doctor-card-image relative lg:absolute top-0 right-0">
            <picture className="">
              <source
                srcSet={AtTheDoctorComparingImageWebp_s}
                media="(max-width: 1152px)"
                type="image/webp"
              />

              <source
                srcSet={AtTheDoctorComparingImage_s}
                media="(max-width: 1152px)"
              />

              <source
                srcSet={AtTheDoctorComparingImageWebp}
                media="(min-width: 1152px)"
                type="image/webp"
              />

              <source
                srcSet={AtTheDoctorComparingImage}
                media="(min-width: 1152px)"
              />

              <img
                className="w-full img-fluid"
                src={AtTheDoctorComparingImage_s}
                alt="Woman cradling her belly experiencing endometriosis pain, wrapped in barbed wire to illustrate discomfort. Not an actual patient."
                width={585}
                height={602}
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AtTheDoctorComparing;
