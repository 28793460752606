import React from 'react';

import AtTheDoctorBg from '../../assets/images/at-the-doctor-hero-bg.jpg';
import AtTheDoctorBgWebp from '../../assets/images/at-the-doctor-hero-bg.webp';
import AtTheDoctorBg_s from '../../assets/images/at-the-doctor-hero-bg-s.jpg';
import AtTheDoctorBgWebp_s from '../../assets/images/at-the-doctor-hero-bg-s.webp';

import AtTheDoctorBg_xs from '../../assets/images/at-the-doctor-hero-bg-xs.jpg';
import AtTheDoctorBgWebp_xs from '../../assets/images/at-the-doctor-hero-bg-xs.webp';
import AtTheDoctorBg_xs2x from '../../assets/images/at-the-doctor-hero-bg-xs@2x.jpg';
import AtTheDoctorBgWebp_xs2x from '../../assets/images/at-the-doctor-hero-bg-xs@2x.webp';

function AtTheDoctorHero() {
  return (
    <section className="relative at-the-doctor-hero">
      <div className="container mx-auto">
        <h1 className="text-white-100 flex flex-col md:flex-row justify-between items-center">
          <span className="text-center md:text-left">
            My doctor and I had a different conversation and it led us in a
            different direction.
          </span>
        </h1>

        <div className="absolute left-0 top-0 flex flex-col md:flex-row at-the-doctor-hero-image">
          <picture className="">
            <source
              srcSet={`${AtTheDoctorBgWebp_xs}, ${AtTheDoctorBgWebp_xs2x} 2x`}
              media="(max-width: 672px)"
              type="image/webp"
            />

            <source
              srcSet={`${AtTheDoctorBg_xs}, ${AtTheDoctorBg_xs2x} 2x`}
              media="(max-width: 672px)"
            />

            <source
              srcSet={AtTheDoctorBgWebp_s}
              media="(max-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={AtTheDoctorBg_s} media="(max-width: 1152px)" />

            <source
              srcSet={AtTheDoctorBgWebp}
              media="(min-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={AtTheDoctorBg} media="(min-width: 1152px)" />

            <img
              className="w-100 img-fluid"
              src={AtTheDoctorBgWebp_xs}
              alt="Smiling woman seated in car who has just talked to her doctor about endometriosis pain and treatment. Not an actual patient."
              width={1440}
              height={612}
              fetchpriority="high"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}

export default AtTheDoctorHero;
