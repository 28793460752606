import React, { useContext } from 'react';
import DoctorDiscussionGuide from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuide';
import DoctorDiscussionGuideToPDF, {
  DDGtoPDFEM
} from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuideToPDF/DoctorDiscussionGuideToPDF';
// import TalkToDocDark, {
//   TalkToDocDarkEM
// } from '../../components/TalkToDocDark/TalkToDocDark';
// import Hero2, { talkToDoctorEM } from '../../components/Hero/Hero2';
// import KnockoutDriver from '../../components/KnockoutDriver/KnockoutDriver';
import Layout from '../../components/Layout';
// import EndometriosisIcon from '../../assets/svgs/EndometriosisIcon.svg';
// import ConvoStarted, {
//   ConvoStartedEM
// } from '../../components/ConvoStarted/ConvoStarted';
import { DDGEM } from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuideEM';
import { DDGStateContext } from '../../contexts/DoctorDiscussionGuideContext';

import AtTheDoctorHero from '../../components/Hero/at-the-doctor-hero';
import AtTheDoctorComparing from '../../components/AtTheDoctorComparing/at-the-doctor-comparing';
import AtTheDoctorCTA from '../../components/AtTheDoctorCTA/at-the-doctor-cta';

import { withPrefix } from 'gatsby';

// import '../../../static/carousel.css';

const Home = () => {
  const DDGState = useContext(DDGStateContext);
  return (
    <Layout section="EM" page="/endometriosis/at-the-doctor/">
      <>
        {/* <Hero2 {...talkToDoctorEM} /> */}
        {/* <TalkToDocDark {...TalkToDocDarkEM} /> */}
        {/* <ConvoStarted {...ConvoStartedEM} /> */}

        <AtTheDoctorHero />

        <AtTheDoctorComparing />

        <DoctorDiscussionGuide {...DDGEM} />

        <DoctorDiscussionGuideToPDF
          {...{
            ...DDGtoPDFEM,
            questions: DDGEM.questions,
            data: DDGState.EM,
            type: 'EM'
          }}
        />

        <AtTheDoctorCTA />
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/endometriosis/at-the-doctor/';
  const title =
    'Endometriosis Dr. Discussion Guide | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Safety Info';
  const pageTitle =
    'Endometriosis Dr. Discussion Guide | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Safety Info';
  const description =
    'Use our guide to start a conversation with your doctor about your endometriosis pain & if MYFEMBREE® is right for you. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.3/html2pdf.bundle.min.js"
        type="application/javascript"
        async
      />
      <link rel="stylesheet" href="/carousel.css" />
    </>
  );
};

export default Home;
